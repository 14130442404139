import React from "react"
import Layout from "../components/layout";
import {graphql} from "gatsby";

export default ({data}) => {
    const tag = data.tag;
    return (
        <Layout>
        <h1>{tag.name}</h1>
        </Layout>
    )
}

export const query = graphql`
  query($slug: String) {
    tag: codewaveTag( slug: { eq: $slug }) {
      slug,
      name
    }
  }
`